<template>
  <div class="loan-application">
    <Container>
      <Card padding="large">
        <Margins>
          <CardHeader
            :header="$t('LOAN_APPLICATION')"
            class="loan-application__card-header"
          />
          <LoanApplicationLogin v-if="showLogin" />
          <LoanApplicationForm v-else class="margins__triple" />
        </Margins>
      </Card>
    </Container>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { Card, CardHeader, Container, Margins } from '@/components';
import { LoanApplicationForm, LoanApplicationLogin } from '@/containers';
import { constants } from '@/mixins';

export default {
  name: 'LoanApplication',
  mixins: [constants],
  components: {
    Container,
    Card,
    CardHeader,
    Margins,
    LoanApplicationLogin,
    LoanApplicationForm,
  },
  computed: {
    ...mapState(['loginInfo']),
    showLogin() {
      return !this.loginInfo;
    },
  },
};
</script>

<style lang="scss">
.loan-application__card-header {
  position: relative;
  z-index: 1;
}
</style>
